<template>
  <v-container>
    <v-row justify="center">
      <v-col cols="12" md="8">
        <p>
          Hey! I'm Gordon Cheng, a passionate and lifelong learner with a keen
          interest in software development and exploring life's intricacies. My
          curiosity drives me to constantly seek out new knowledge and
          experiences.
        </p>

        <h2 class="text-h5 mt-4 mb-2">Technologies & Platforms</h2>
        <p>I work with a variety of technologies and platforms, including:</p>
        <v-row class="mt-2">
          <v-col
            v-for="tech in technologies"
            :key="tech.name"
            cols="6"
            sm="4"
            md="3"
            lg="2"
          >
            <v-tooltip :text="tech.name" location="bottom">
              <template #activator="{ props }">
                <div v-bind="props" class="tech-item">
                  <div class="tech-logo-wrapper">
                    <div class="tech-logo">
                      <SvgWrapper :svg="tech.logo" />
                    </div>
                  </div>
                  <div class="tech-name">
                    {{ tech.name }}
                  </div>
                </div>
              </template>
            </v-tooltip>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script setup lang="ts">
import { ref, Component, markRaw } from "vue"
import SvgWrapper from "@/components/SvgWrapper.vue"
import { createRawSvgRenderer } from "@/utils/svgUtils"

import TypescriptIcon from "@/assets/icons/typescript.svg"
import JavascriptIcon from "@/assets/icons/javascript.svg"
import PythonIcon from "@/assets/icons/python.svg"
import KotlinIcon from "@/assets/icons/kotlin.svg"
import RustIcon from "@/assets/icons/rust.svg"
import CSharpIcon from "@/assets/icons/csharp.svg"
import CPlusPlusIcon from "@/assets/icons/cplusplus.svg"
import CIcon from "@/assets/icons/c.svg"

import ViteIconRaw from "@/assets/icons/vite.svg?raw"

import ReactIcon from "@/assets/icons/react.svg"
import ReduxIcon from "@/assets/icons/redux.svg"
import VueIcon from "@/assets/icons/vue.svg"
import NetlifyIcon from "@/assets/icons/netlify.svg"

import AWSIcon from "@/assets/icons/aws.svg"

import DockerIcon from "@/assets/icons/docker.svg"
import JenkinsIcon from "@/assets/icons/jenkins.svg"
import CircleCIIcon from "@/assets/icons/circleci.svg"

import GitIcon from "@/assets/icons/git.svg"

import ElectronJs from "@/assets/icons/electron.svg"
import FastApi from "@/assets/icons/fastapi.svg"
import Sqlite from "@/assets/icons/sqlite.svg"

import DotNet from "@/assets/icons/dotnet.svg"
import SqlServer from "@/assets/icons/sqlserver.svg"

import Atlassian from "@/assets/icons/atlassian.svg"

import PytestIcon from "@/assets/icons/pytest.svg"
import JestIcon from "@/assets/icons/jest.svg"
import CypressIconRaw from "@/assets/icons/cypress.svg?raw"

import MirageJs from "@/assets/icons/mirage.svg"
import PostmanIcon from "@/assets/icons/postman.svg"

interface Technology {
  name: string
  logo: Component
}

const technologies = ref<Technology[]>([
  { name: "TypeScript", logo: markRaw(TypescriptIcon as unknown as object) },
  { name: "Javascript", logo: markRaw(JavascriptIcon as unknown as object) },
  { name: "Python", logo: markRaw(PythonIcon as unknown as object) },

  { name: "Kotlin", logo: markRaw(KotlinIcon as unknown as object) },
  { name: "C#", logo: markRaw(CSharpIcon as unknown as object) },
  { name: "Rust", logo: markRaw(RustIcon as unknown as object) },
  { name: "C++", logo: markRaw(CPlusPlusIcon as unknown as object) },
  { name: "C", logo: markRaw(CIcon as unknown as object) },

  {
    name: "Vite",
    logo: markRaw(createRawSvgRenderer(ViteIconRaw) as unknown as object),
  },
  {
    name: "ReactJS and React Native",
    logo: markRaw(ReactIcon as unknown as object),
  },
  { name: "Redux", logo: markRaw(ReduxIcon as unknown as object) },
  { name: "VueJS", logo: markRaw(VueIcon as unknown as object) },
  { name: "Netlify", logo: markRaw(NetlifyIcon as unknown as object) },

  { name: "AWS", logo: markRaw(AWSIcon as unknown as object) },

  { name: "Docker", logo: markRaw(DockerIcon as unknown as object) },
  { name: "Jenkins", logo: markRaw(JenkinsIcon as unknown as object) },
  { name: "CircleCI", logo: markRaw(CircleCIIcon as unknown as object) },
  { name: "Git", logo: markRaw(GitIcon as unknown as object) },

  { name: "ElectronJS", logo: markRaw(ElectronJs as unknown as object) },
  { name: "FastAPI", logo: markRaw(FastApi as unknown as object) },
  { name: "SQLite", logo: markRaw(Sqlite as unknown as object) },

  { name: ".Net Framework", logo: markRaw(DotNet as unknown as object) },
  {
    name: "T-SQL and SQL Server",
    logo: markRaw(SqlServer as unknown as object),
  },

  { name: "Atlassian", logo: markRaw(Atlassian as unknown as object) },
  { name: "Pytest", logo: markRaw(PytestIcon as unknown as object) },
  { name: "Jest", logo: markRaw(JestIcon as unknown as object) },
  {
    name: "Cypress",
    logo: markRaw(createRawSvgRenderer(CypressIconRaw) as unknown as object),
  },
  { name: "MSTest", logo: markRaw(DotNet as unknown as object) },

  { name: "MirageJS", logo: markRaw(MirageJs as unknown as object) },
  { name: "Postman", logo: markRaw(PostmanIcon as unknown as object) },
])
</script>

<style scoped>
.tech-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  height: 85px;
}

.tech-logo-wrapper {
  width: 48px;
  height: 48px;
  margin-bottom: 8px;
}

.tech-logo {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.svg-wrapper {
  width: 100%;
  height: 100%;
}

.tech-name {
  font-size: 12px;
  line-height: 1.2;
  max-width: 100%;
  overflow: hidden;
  display: -webkit-box;
  line-clamp: 2;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}
.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
