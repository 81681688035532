import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router"
import HomePage from "@/views/HomePage.vue"
import BlogPage from "@/views/BlogPage.vue"
import BlogPost from "@/views/BlogPost.vue"
import AboutPage from "@/views/AboutPage.vue"
import PerfectTyping from "@/views/PerfectTyping.vue"
import ProjectPage from "@/views/projects/ProjectPage.vue"

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "HomePage",
    component: HomePage,
  },
  {
    path: "/blog",
    name: "BlogPage",
    component: BlogPage,
  },
  {
    path: "/blog/:year/:slug",
    name: "BlogPost",
    component: BlogPost,
    props: true,
  },
  {
    path: "/about",
    name: "AboutPage",
    component: AboutPage,
  },
  {
    path: "/projects",
    name: "Projects",
    component: ProjectPage,
  },
  {
    path: "/projects/:slug",
    name: "ProjectPage",
    component: ProjectPage,
    props: true,
  },
  {
    path: "/projects/perfect-typing",
    name: "PerfectTypingBase",
    component: PerfectTyping,
  },
  {
    path: "/projects/perfect-typing/:title",
    name: "PerfectTypingWithTitle",
    component: PerfectTyping,
    props: true,
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes,
})

export default router
